.btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_container>button {
    background-color: #5c0380 !important;
    border: none;
    color: #f5f5f5;
    margin-top: 10px;
    margin-left: 10px;
}

.btn_container>button:hover {
    cursor: pointer;
    background-color: #5c0380 !important;
}


table tr {
    border: 1px solid #fff;
    border-radius: 5px !important;
}

table th {
    background-color: #321c60 !important;
    color: #c2b8d8 !important;
}

table td {
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ecbbff !important;
    color: #5c0380 !important;
}

tr:hover {
    background-color: #321c60;
    transform: scale(1.01);
    border: 1px solid #fff;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.tableFixHead {
    overflow: auto;
    height: 500px;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Just common table stuff. Really. */
table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    padding: 8px 16px;
}

th {
    background: #eee;
}

@media only screen and (min-width: 320px) and (max-width: 520px) {
    .btn_container {
        display: block;
        
    }
   
}