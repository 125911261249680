.navbar2{
    background-color: white !important;
    border: 1px solid #FBFAFC !important;
    box-shadow: rgb(41, 0, 94) 0px 5px 20px -10px;

}
@media (min-width: 320px) and (max-width: 767px) {	
	
	ul,li{
        
        background-color: #321c60!important;
        width: 70%;
        border-radius: 10px;
        margin-top: 10px;
       color:#ffffff !important;        
       
    }
    .nav_link{
        color:#ffffff !important;  
    }
    #navbarDropdown{
        color:#ffffff !important;  
    }
    .dp-menu{
        border: none !important;
    }
    .dp-item{
        color: #fff!important;
    }
    .team-rank-btn-container{
        margin-top: 10px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
   
}
