@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);


.mobile-form{
    display: none;
}



.btn-contactus:hover {
    background: #ff6a5b;
    background: -webkit-linear-gradient(legacy-direction(to right), #ff6a5b 0%, #ff4d7e 100%);
    background: -webkit-gradient(linear, left top, right top, from(#ff6a5b), to(#ff4d7e));
    background: -webkit-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
    background: -o-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
    background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
}




.contact-box {

    box-shadow: rgba(88, 42, 126, 0.24) 0px 3px 8px;
    padding: 0 30px !important;
    border-radius: 10px;
    width: 450px;

}

.contact-box,
.contact-box-child {
    background: rgba(138, 98, 168, 0.151);
    background: linear-gradient(196deg, rgba(139, 115, 158, 0.267) 21%, rgba(111, 94, 126, 0.205) 52%, rgba(187, 113, 240, 0.123) 77%);
}

.from-absolute {
    margin-top: 100px;
}

.img-absolute {
    margin-top: 50px;
    margin-left: 400px;
    border-radius: 5px;
}

.contact-box-child {
    width: 500px;
    height: 500px;
    margin-left: 40px;
    margin-top: 200px !important;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 100px;
}

.footer-absolute {
    margin-top: 200px;
    margin-left: 290px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media (min-width: 320px) and (max-width: 767px) {
    .contactus-wrapper{
        display: none;
    }  
    .mobile-form{        
        display: block;
        background-color:rgb(134, 179, 236);
       
    }
    .contact-box-child{
      background-color:rgb(238, 167, 101);
    margin-top: -130px;
       width: 320px;
       height: 600px;
    }
    .contact-info{
        margin-left: 10px;
    }

}

/* mobile form code  */

.contact-section .form-control{
    border: 1px solid  #2fbfbc;
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
  }
  h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 900;
    color: #101f1d;
    font-size: 35px;
    letter-spacing: 1.25px;
    margin-top: 0;
  }
  .contact-fields {
    padding: 40px 0 0;
  }
  .phone {
    padding-top: 50px;
  }
  .phone,
  .email,
  .working-hours {
    margin-bottom:30px;
  }
  .phone a,
  .email a,
  .location a {
    color: #fd4f00;
    font-size:18px;
  }
  .contact-h2 {
    color: #fd4f00;
    padding-top:20px;
  }
  .contact-fields .form-group {
    margin-bottom: 35px;
  }
  .contact-info {
    margin-top: -40px;
  }
  .contact-fields .form-group input,
  .contact-fields .form-group textarea {
    font-size: 16px;
  }
  .location a.btn.btn-accent {
    padding: 12px 26px 10px;
    margin-top: 30px;
    font-size: 16px;
  }
  .location a.btn.btn-accent img {
    margin-right: 20px;
  }
  .location hr {
    margin-bottom: 10px;
  }
  .btn {
    font-size: 15px;
    padding: 22px 46px 18px;
    line-height: 1;
    text-transform: uppercase;
    border: none;
    border-radius: 0;
    position: relative;
    letter-spacing: 0.3px;
  }
  .btn.btn-accent {
    background: #2fbfbc;
    color: white;
  }
  .btn.btn-accent:hover {
    background: #269694;
  }
  .ct-newsletter-section .container {
    width: 100%;
    max-width: 1360px;
  }
  .ct-section-head,
  .ct-section-header {
    font-family: 'Raleway', sans-serif;
    font-weight: 900;
    color: #101f1d;
    font-size: 35px;
    text-transform: uppercase;
    padding-top: 45px;
    letter-spacing: 1.25px;
    margin-bottom: 5px;
  }
  .ct-section-header {
    text-align: center;
  }
  .ct-newsletter-inline {
    display: table;
    width: 100%;
    color: white;
    margin: 69px 0 138px;
    text-align: center;
  }
  .ct-newsletter-inline__facebook {
    background-color: #3b5997;
  }
  .ct-newsletter-inline__facebook, .ct-newsletter-inline__twitter {
    width: 16%;
  }
  a {
    -webkit-transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1);
    transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1);
  }
  .ct-newsletter-inline__facebook i, .ct-newsletter-inline__twitter i {
    font-size: 109px;
    padding-top: 25px;
  }
  .ct-newsletter-inline__facebook span, 
  .ct-newsletter-inline__twitter span {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 4px;
    display: block;
    padding-top: 15px;
  }
  .ct-newsletter-inline__twitter {
    background-color: #2fc2ee;
  }
  .ct-newsletter-inline__facebook, 
  .ct-newsletter-inline__twitter {
    width: 16%;
    padding-top: 25px;
  }
  .ct-newsletter-inline__form {
    background-color: #fd4f00;
    padding: 24px;
  }
  .ct-newsletter-inline__form .ct-newsletter {
    text-align: left;
    width: 100%;
    max-width: 668px;
    margin: 0px auto;
  }
  .ct-newsletter-inline__form .form-inline {
    display: table;
    width: 100%;
    height: 65px;
  }
  .ct-newsletter-inline__form .form-inline input {
    width: 50%;
    width: calc(100% - 137px);
    padding: 0 40px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #111f1d;
    text-transform: uppercase;
  }
  .ct-newsletter-inline__form .form-inline input, 
  .ct-newsletter-inline__form .form-inline .btn {
    display: table-cell;
    vertical-align: middle;
    height: 65px;
    border: none;
    margin: 0;
  }
  .ct-newsletter-inline__form .form-inline .btn {
    width: 137px;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
    padding-left: 0;
    padding-right: 3px;
    text-align: center;
  }
  .ct-newsletter-inline__form .form-inline input, .ct-newsletter-inline__form .form-inline .btn {
    display: table-cell;
    vertical-align: middle;
    height: 65px;
    border: none;
    margin: 0;
  }
  .btn.btn-dark {
    background: #111f1d;
    color: white;
  }
  .btn {
    font-size: 15px;
    padding: 22px 46px 18px;
    line-height: 1;
    text-transform: uppercase;
    border: none;
    border-radius: 0;
    position: relative;
    letter-spacing: 0.3px;
  }
  @media screen and (min-width: 980px) {
    .contact-info {
      padding-left: 110px;
    }
   
    
  }