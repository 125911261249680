#card {
  width: 340px !important;
  height: 264px !important;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.containerHome {
  background-color: transparent !important;
}





@media (max-width: 768px) {
  .card-wrapper {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .card-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }
}