.About-box {
    
    background-color: rgba(73, 2, 131, 0.034);
    height: 270px;
    margin-top: 30px !important;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgb(144, 145, 146) 0px 10px 10px -10px;
}
@media (min-width: 320px) and (max-width: 767px){
    .about-img{
        display: none !important;
    }
    .About-box{
        padding: 1px;
    }
    
}
