.login_form_container {   
    background-color: rgb(141, 138, 138);
    border: 0px solid black;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    padding: 10px;
    
}

.input_wrapper {
    background-color: green;    
    display: flex;
    justify-content: center;
    align-items: center;

}